import React from 'react';
import Footer from './components/Footer';
import Header from './components/Header';

interface PrivacyNotice {
  name: string;
  fileName: string;
  updatedAt?: string;
  fileSize?: string;
}

function App() {
  const files: PrivacyNotice[] = [
    {
      name: 'WGNH Visa Program Presentation',
      fileName: '/files/wgnh-visa-program-presentation_20240116_1030.pdf',
      fileSize: '69.2MB',
      updatedAt: '2024-01-16 10:30',
    },
    {
      name: 'Agency Agreement Visa Premier Program',
      fileName: '/files/agency-agreement-visa-premier-program_20240116_1030.pdf',
      fileSize: '280KB',
      updatedAt: '2024-01-16 10:30',
    },
    {
      name: 'Sales Price Visa Premier Ownership',
      fileName: '/files/sales-price-visa-premier-ownership_20240119_1700.pdf',
      fileSize: '263KB',
      updatedAt: '2024-01-19 17:00',
    },
    {
      name: 'Sales Price Premier Leasehold',
      fileName: '/files/sales-price-visa-premier-leasehold_20240119_1700.pdf',
      fileSize: '216KB',
      updatedAt: '2024-01-19 17:00',
    },
    {
      name: 'E-Brochure WGNH',
      fileName: '/files/e-brochure-wgnh_20240123_1700.pdf',
      fileSize: '2.4MB',
      updatedAt: '2024-01-23 17:00',
    },
    {
      name: 'Gallery',
      fileName: '/files/gallery_20240130_1300.zip',
      fileSize: '54.3MB',
      updatedAt: '2024-01-30 13:00',
    },
    {
      name: 'Sticker',
      fileName: '/files/sticker_20240116_1100.zip',
      fileSize: '7MB',
      updatedAt: '2024-01-16 11:00',
    },
    {
      name: 'Leasehold & Freehold Banner',
      fileName: '/files/banner_20240123_1700.zip',
      fileSize: '9.7MB',
      updatedAt: '2024-01-23 17:00',
    },
  ];

  return (
    <div className="antialiased text-gray-900 min-h-screen flex flex-col">
      <Header />
      <div className="container px-5 pt-12 pb-8 md:pt-24 mx-auto bg-white flex-grow flex justify-between flex-col">
        <section className="text-gray-600 body-font">
          <div className="flex flex-col text-center w-full">
            <h1 className="sm:text-3xl text-2xl font-semibold title-font mb-4 text-gray-900">
              PREMIER THAILAND INVESTMENT PROGRAM
            </h1>
            <p className="lg:w-2/3 mx-auto text-base">
              Thank you for being part of our event. Below, you can download the exclusive
              files:
            </p>
          </div>
        </section>
        <div className="grid lg:grid-cols-3 gap-4 lg:gap-6 py-12 md:py-20">
          {files &&
            files.map((file) => (
              <div
                key={file.fileName}
                className="border-gray-200 border p-4 rounded-lg text-gray-900 hover:text-primary hover:border-primary"
              >
                <a
                  href={`https://links.cissagroup.com${file.fileName}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="flex items-center">
                    <div>
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8 mr-4"
                      >
                        <path
                          d="M7.50005 1.04999C7.74858 1.04999 7.95005 1.25146 7.95005 1.49999V8.41359L10.1819 6.18179C10.3576 6.00605 10.6425 6.00605 10.8182 6.18179C10.994 6.35753 10.994 6.64245 10.8182 6.81819L7.81825 9.81819C7.64251 9.99392 7.35759 9.99392 7.18185 9.81819L4.18185 6.81819C4.00611 6.64245 4.00611 6.35753 4.18185 6.18179C4.35759 6.00605 4.64251 6.00605 4.81825 6.18179L7.05005 8.41359V1.49999C7.05005 1.25146 7.25152 1.04999 7.50005 1.04999ZM2.5 10C2.77614 10 3 10.2239 3 10.5V12C3 12.5539 3.44565 13 3.99635 13H11.0012C11.5529 13 12 12.5528 12 12V10.5C12 10.2239 12.2239 10 12.5 10C12.7761 10 13 10.2239 13 10.5V12C13 13.1041 12.1062 14 11.0012 14H3.99635C2.89019 14 2 13.103 2 12V10.5C2 10.2239 2.22386 10 2.5 10Z"
                          fill="currentColor"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <h2 className="text-sm md:text-base font-semibold mb-3">
                        {file.name}
                      </h2>
                      {file.fileSize && (
                        <p className="text-sm text-gray-500">
                          File size: {file.fileSize}
                        </p>
                      )}
                      {file.updatedAt && (
                        <p className="text-sm text-gray-500">
                          Last updated: {file.updatedAt}
                        </p>
                      )}
                    </div>
                  </div>
                </a>
              </div>
            ))}
        </div>
        <p className="text-gray-500 text-center text-sm">
          If you have any inquiries or require additional information, please feel free to{' '}
          <a href="mailto:sales@cissagroup.com" className="underline">
            contact our concierge
          </a>
          .
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default App;
