import React from 'react';

type Props = {};

const Footer = (props: Props) => {
  return (
    <footer>
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5">
          <p className="text-gray-400 text-xs text-center">
            © 2024 CISSA Group Co., Ltd.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
